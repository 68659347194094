<template>
  <section class="section contacts-section">
    <div class="container">
      <div :class="`contacts dark-bg ${props.modal ? 'contacts--modal' : ''}`">
        <span id="application" class="contacts__hash">Отправить заявку</span>
        <div class="contacts__info">
          <h2 class="title title--invert">
            Отправить заявку <br />
            на консультацию
          </h2>
          <p class="text">
            Консультация менеджера по вопросу разработки программ и приложений, расчет цены разработки IT-решений, демонстрация примеров
            готовых приложений.
          </p>
        </div>
        <div v-if="!isFormSend" class="contacts__form-container">
          <form
            class="contacts__form"
            @submit.prevent="handleContactsForm"
            name="contactsForm"
            :id="props.modal ? 'modalContactForm' : 'contactForm'"
          >
            <input v-model="state.name" type="text" placeholder="Имя" class="contacts__input text-input" @blur="v.name.$touch" />
            <span v-if="v.name.$error" class="error-message">
              {{ v.name.$errors[0]?.$message }}
            </span>
            <input v-model="state.email" type="text" placeholder="Email" class="contacts__input text-input" @blur="v.email.$touch" />
            <span v-if="v.email.$error" class="error-message">
              {{ v.email.$errors[0]?.$message }}
            </span>
            <input v-model="state.phone" type="text" placeholder="Телефон" class="contacts__input text-input" @blur="v.phone.$touch" />
            <span v-if="v.phone.$error" class="error-message">
              {{ v.phone.$errors[0]?.$message }}
            </span>
            <span v-if="errorMessage" class="error">
              {{ errorMessage }}
            </span>
            <textarea
              v-if="props.modal"
              v-model="state.comment"
              type="text"
              placeholder="Комментарий"
              class="contacts__input text-input textarea"
              rows="4"
            />
            <button v-if="!isProcessActive" type="submit" class="button gradient-bg contacts__btn">Отправить заявку</button>
          </form>
          <span v-if="!props.modal" class="contacts__choise"> или </span>
          <contacts v-if="!props.modal" />
        </div>
        <div v-else class="contacts__form-container">
          <span class="success">
            {{ 'Сообщение отправлено, мы обязательно свяжемся с вами!' }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMail } from '#imports';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import contacts from '~/components/blocks/contacts.vue';

interface IState {
  name: string;
  email: string;
  phone: string;
  comment: string;
}

interface IProps {
  modal?: boolean;
}

const props = defineProps<IProps>();

const state = reactive<IState>({
  name: '',
  email: '',
  phone: '',
  comment: '',
});

const emailRegex = /^(?=.{1,320}$)[a-zA-Z][\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
const phoneRegEx = /^(8|\+7)\d{10}$/;
const emailValidate = (value: string) => emailRegex.test(value);
const phoneValidator = (value: string) => phoneRegEx.test(value);

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage('Обязательное поле', required),
      minLength: 2,
    },
    email: {
      required: helpers.withMessage('Обязательное поле', required),
      email: helpers.withMessage('Неверный формат поля email', emailValidate),
    },
    phone: {
      required: helpers.withMessage('Обязательное поле', required),
      phoneValidator: helpers.withMessage('Формат номера: +70000000000 ', phoneValidator),
    },
  };
});

const v = useVuelidate(rules, state);
const errorMessage = ref<string>('');
const isProcessActive = ref<boolean>(false);
const isFormSend = ref<boolean>(false);
const handleContactsForm = async () => {
  const isFormValid = await v.value.$validate();
  if (isFormValid) {
    try {
      isProcessActive.value = true;
      const mail = useMail();
      await mail.send({
        from: 'no-replay@sales-area.ru',
        subject: 'Заявка с сайта terpo.ru',
        html: `
      <html>
          <head>
              <meta charset='utf-8'>
              <style>
                  a {
                    text-decoration: none;
                    color: #409eff;
                  }
                  a:visited {
                      color: #409eff;
                  }

                  a.button:visited {
                      color: #ffffff;
                  }
              </style>
            </head>
            <body>
            <center>
              <div style="width: 600px;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width:280px">
                  <tbody>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <table align="center" border="0" cellpadding="0" cellspacing="0" height="52" width="100%"
                             style="background-color:#67c23a;border-radius:16px;color:#ffffff">
                        <tbody>
                        <tr>
                          <td align="left" valign="middle">
                            <table border="0" cellpadding="0" cellspacing="0" style="padding:0 16px 0 16px">
                              <tbody>
                              <tr>
                                <td align="left" valign="middle">
                                  <a href="https://terpo.ru"
                                     rel="noopener noreferrer"
                                     target="_blank"
                                     data-link-id="24"
                                     style="color:#ffffff;
                                     font-family:'arial', 'helvetica', sans-serif;
                                     font-size:18px;
                                     line-height:18px;
                                     text-align:center;
                                     text-decoration:none"
                                     >
                                      TERPO
                                  </a>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table border="0" cellpadding="0" cellspacing="0" width="100%"
                             style="background-color:#ffffff;border-radius:16px">
                        <tbody>
                        <tr>
                          <td align="center">
                            <table border="0" cellpadding="0" cellspacing="0" width="90%">
                              <tbody>
                              <tr>
                                <td height="24">
                                  <div style="font-size:24px;height:24px;line-height:24px"> &nbsp;</div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                    <tbody>
                                    <tr>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="top">
                                        <div
                                            style="color:#262626;font-family:'arial' , 'helvetica' , 'tahoma' , sans-serif;font-size:22px;font-weight:bold;line-height:26px;margin-bottom:8px">
                                          Заявка с сайта terpo.ru!
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td align="left" valign="top">
                                        <div
                                            style="color:#262626;font-family:'arial' , 'helvetica' , 'tahoma' , sans-serif;font-size:16px;line-height:20px;max-width:536px">
                                          <br>
                                          Имя: ${state.name}<br>
                                          Email: ${state.email}<br>
                                          Телефон: ${state.phone}
                                          ${state.comment ? 'Комментарий: ' + state.comment : ''}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td height="8">
                                        <div style="font-size:8px;height:8px;line-height:8px"> &nbsp;</div>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td height="24">
                                  <div style="font-size:24px;height:24px;line-height:24px"> &nbsp;</div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td align="center">
                            <table border="0" cellpadding="0" cellspacing="0" width="90%">
                              <tbody>
                              <tr>
                                <td height="1">
                                  <div
                                      style="border-bottom-color:#ececec;border-bottom-style:solid;border-bottom-width:1px;height:1px;line-height:1px">
                                    &nbsp;
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="24">
                      <div style="font-size:24px;height:24px;line-height:24px">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="16">
                      <div style="font-size:16px;height:16px;line-height:16px"> &nbsp;</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
            </div>
          </center>
      </body>
      </html>
      `,
      });
      isFormSend.value = true;
      isProcessActive.value = false;
    } catch {
      isProcessActive.value = false;
      errorMessage.value = 'Что-то пошло не так. Попробуйте позже.';
    }
  }
};
</script>

<style scoped lang="scss">
.contacts {
  border-radius: 32px;
  padding: 63px 71px;
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 32px;
  position: relative;

  &--modal {
    flex-direction: row-reverse;
    max-width: 100%;

    .title {
      font-size: 40px;
      line-height: 50px;
    }

    .contacts__info {
      max-width: 50%;
    }

    .contacts__form-container {
      max-width: 50%;
      width: 560px;
    }

    .text-input {
      max-width: 100%;
      width: 100%;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    z-index: -0;
    background-image: image-set(url('/img/bg/contacts-bg-1.webp') type('image/webp'), url('/img/bg/contacts-bg-1.jpg') type('image/jpeg'));
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.08;
    border-radius: 32px;
  }

  &__info {
    max-width: 640px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;
    z-index: 1;
  }

  &__hash {
    position: absolute;
    font-size: 0;
    top: -200px;
    left: 0;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 1;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  &__choise {
    color: var(--accent-color);
    font-size: var(--standard-text);
    line-height: var(--standard-line);
    text-align: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 134px;
      max-width: 40%;
      background-color: #525252;
      position: absolute;
      top: calc(50% - 1px);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }
}

.error-message {
  color: var(--accent-color);
  font-weight: var(--medium);
  font-size: var(--article-text);
  line-height: var(-article-line);
}

.error {
  color: var(--accent-color);
  font-weight: var(--medium);
  text-align: center;
  font-size: var(--article-text);
  line-height: var(-article-line);
}

.success {
  text-align: center;
  color: var(--accent-color);
  font-weight: var(--medium);
  font-size: var(--standard-text);
  line-height: var(--standard-line);
}

@media (max-width: 1024px) {
  .contacts {
    padding: 63px 40px;

    &__hash {
      top: -160px;
    }
  }
}

@media (max-width: 1200px) {
  .contacts {
    &__btn {
      padding: 16px 45px;
    }
  }
}

@media (max-width: 1024px) {
  .contacts {
    &--modal {
      flex-direction: column;

      .contacts__info,
      .contacts__form-container {
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .contacts {
    padding: 18px;
    border-radius: 0;
    margin: 0 -18px;
    flex-direction: column;

    &--modal {
      margin: 0;
      width: 100vw;
      max-width: 100vw;
      box-sizing: border-box;
      justify-content: flex-start;
      padding-bottom: 100px;

      &::before {
        border-radius: 0;
      }

      .title {
        font-size: 30px;
        line-height: 40px;
      }
    }

    &__hash {
      top: -120px;
    }

    &__input {
      max-width: 100%;
    }

    &__info {
      gap: 32px;
    }

    &__choise {
      &::before,
      &::after {
        width: 40%;
      }
    }
  }

  .contacts-section {
    padding: 0;
  }
}
</style>
